import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import heroScreenshotImageSrc from "images/landing-image-1.png";
import teamImageSrc from "images/team-illustration-2.png";
import motionLifeImageSrc from "images/motion-life.gif";
import radarChartSrc from "images/radar-chart.png";
import C1 from "images/pillars/c1.png";
import C2 from "images/pillars/c2.png";
import C3 from "images/pillars/c3.png";
import C4 from "images/pillars/c4.png";
import HeroBgImage from "images/hero-bg-img.png";


import {ReactComponent as AnywhereIcon} from "images/anywhereIcon.svg";
import {ReactComponent as KineticIcon} from "images/kinetic.svg";
import Footer from "./components/footers/FiveColumnWithInputForm";
import TwoColGrid from "./components/features/TwoColGrid";

const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`text-primary-500`;


export default () => {
  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
        <TwoColGrid
            heading="Discover the Four Pillars of the MAI Motion"
            cards={[
                {
                    image: C1,
                    title: "Comprehensive Motion Analysis",
                    description:
                        'MAI-Motion offers a comprehensive analysis of musculoskeletal motion, enabling clinicians and researchers to gain deeper insights into joint dynamics, movement patterns, and biomechanical parameters.',
                },
                {
                    image: C2,
                    title: "3D Mesh Technology",
                    description: "Our advanced 3D mesh technology captures detailed motion data, creating a visual representation of joint movement with unparalleled accuracy. This allows for a comprehensive understanding of the intricacies of musculoskeletal motion.",
                },
                {
                    image: C3,
                    title: "Integration with 3D Volumetric MRI",
                    description: "MAI-Motion goes beyond traditional motion analysis by integrating with 3D volumetric MRI data. This integration enables the correlation of motion data with structural information, providing a holistic view of musculoskeletal health.",
                },
                {
                    image: C4,
                    title: "AI-Driven Decision Support",
                    description: "Powered by artificial intelligence,\n" +
                        "MAI-Motion offers intelligent decision support tools that aid in diagnosis, treatment planning, and rehabilitation strategies. Our algorithms analyse complex motion data and provide evidence-based recommendations for optimal patient care.",
                },
            ]}
        />
      <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={motionLifeImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
        description= "At MAI-Motion, we’ve harnessed the power of advanced 3D mesh technology and integrated it with 3D volumetric MRI object detection. Our tool is designed to provide comprehensive, nuanced, and accurate insights into human motion, underlying anatomical structures, and muscle coordination."
        subdescription= "We’re shifting the paradigm of musculoskeletal health by providing a holistic view, combining the external dynamics of movement with the internal mechanics of the musculoskeletal system."
        hideButton
        textOnLeft={false}
      />
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            <HighlightedText>Our</HighlightedText> Unique Approach
          </>
        }
        description="At MAI-Motion, we’re not just improving upon existing motion analysis systems; we’re pioneering a whole new approach. We integrate 3D motion mesh data with 3D volumetricMRI object detection for human anatomy, offering a holistic view of musculoskeletal health.This integration is an industry first, pushing the boundaries of what’s possible in motion analysis and MRI."
        imageSrc={radarChartSrc}
        showDecoratorBlob={false}
        primaryButtonUrl="https://app.maimotion.com"
        primaryButtonText="GET STARTED"
        features={[
          {
            Icon: AnywhereIcon,
            title: "Available Anywhere",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-blue-900`
          },
          {
            Icon: KineticIcon,
            title: "Kinetic Analysis",
            description: "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-blue-900`
          }
        ]}
      />

        <MainFeature
            subheading={<Subheading>Benefits</Subheading>}
            imageSrc={heroScreenshotImageSrc}
            imageBorder={true}
            imageDecoratorBlob={true}
            heading={ <>
                <HighlightedText>The </HighlightedText> Benefits
            </>}
            description= "With MAI-Motion, healthcare providers can gain a deep understanding of each patient’s unique movement patterns, joint dynamics, and muscle coordination. Our solution enables more accurate diagnoses, more effective treatment plans, and better patient outcomes."
            subdescription= "MAI-Motion gives clinicians, researchers, and patients a deep understanding of motion health like never before. Our tool helps clinicians diagnose and manage musculoskeletal conditions more effectively. Researchers can glean new insights into human movement,while patients gain access to more accurate and personalised treatment."
            hideButton
            textOnLeft={false}
        />
        <Pricing
            description="Find the perfect plan for your needs by comparing the features included in each. Whether you're an individual patient, a doctor managing a practice, or an administrator overseeing a clinic, we have tailored plans to suit your requirements."
            heading={
                <>
                    Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
                </>
            }
        />
        <MainFeature
            subheading={<Subheading>Experts</Subheading>}
            imageSrc={teamImageSrc}
            imageBorder={true}
            imageDecoratorBlob={true}
            heading={ <>
                <HighlightedText>Designed by </HighlightedText> Leading Experts
            </>}
            description= "Our technology was designed in collaboration between top orthopaedic surgeons, leading professors in computer vision, and renowned academics in bio mechanics. This blend of expertise ensures that MAI-Motion is medically relevant, technologically advanced, and scientifically robust."
            hideButton
            bgImage={HeroBgImage}
        />

      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
            {
                "stars": 5,
                "profileImageSrc": "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
                "heading": "Transformative Experience with MAI Motion!",
                "quote": "Using the MAI Motion App has been truly life-changing. The integration of advanced 3D mesh technology with 3D volumetric MRI provided me with unprecedented insights into my hip motion. The experience with the MAI Motion App throughout my recovery was phenomenal, and the care provided was exceptional. I can't thank the MAI Motion team enough for their outstanding support and expertise.",
                "customerName": "Charlotte Hale",
            },
            {
                "stars": 5,
                "profileImageSrc": "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
                "heading": "Exceptional Recovery with MAI Motion!",
                "quote": "The MAI Motion App has transformed my recovery journey. By integrating advanced 3D mesh technology with 3D volumetric MRI, it gave me incredible insights into my knee motion. The entire experience with the app was seamless, and the care I received was outstanding. I'm incredibly grateful to the MAI Motion team for their exceptional support and innovative approach.",
                "customerName": "Adam Cuppy",
            }
        ]}
      />
      <FAQ />
      <GetStarted/>
      <Footer/>
    </AnimationRevealPage>
  );
}
