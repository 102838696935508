import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import Header from "../headers/light.js";

import {ReactComponent as SvgDecoratorBlob1} from "images/svg-decorator-blob-1.svg";
import HeroBgImage from "images/hero-bg-img.png";
import JeffGIF from "images/jef-video.gif";
import Innovate from "images/innovate.png";
import MskDoctors from "images/msk-doctors.png";
import RegenMan from "images/regen-man.png";
import UniversityOfLincoln from "images/university-of-lincoln.png";
import {PrimaryButton as PrimaryButtonBase} from "../misc/Buttons";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-1/2 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Button = styled(PrimaryButtonBase)`
    ${tw`rounded-full uppercase tracking-wider py-4 text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
    `;
const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const IllustrationContainer = styled.div`${tw`flex justify-center lg:justify-end items-center relative`} 
.hero-img{
${tw`min-w-0 w-full max-w-lg xl:max-w-3xl absolute opacity-10 left-1/2`}
}
.bg-img{
${tw`min-w-0 w-full max-w-lg xl:max-w-3xl z-20`}
}
`;


// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-sm tracking-wider font-semibold text-gray-600`}
  }
  .images{
  ${tw`flex items-center flex-wrap justify-center md:-ml-4 md:justify-start`}
  }
  img {
    ${tw`w-1/2 md:w-1/3 p-2 md:p-4 opacity-50`}
    filter: brightness(20%) contrast(200%)
  }
`;
const HighlightedText = tw.span`text-primary-500`;

export default ({roundedHeaderButton}) => {
    return (
        <>
            <Header roundedHeaderButton={roundedHeaderButton}/>
            <Container>
                <TwoColumn>
                    <LeftColumn>
                        <Heading>
                            Transforming Musculoskeletal <HighlightedText>Health</HighlightedText>
                        </Heading>
                        <Paragraph>
                            Integrating Advanced 3D Mesh Technology with 3D Volumetric MRI for Unprecedented Insights
                            into Human Motion.
                        </Paragraph>
                        <Button onClick={() => window.open("https://app.maimotion.com/register")}>Get Started</Button>
                        <CustomersLogoStrip>
                            <p>Supported By</p>
                            <div className="images">
                                <img src={Innovate} alt="Innovate UK"/>
                                <img src={UniversityOfLincoln} alt="University of Lincoln"/>
                                <img src={MskDoctors} alt="MSK Doctors"/>
                                <img src={RegenMan} alt="Regeneration Man"/>
                            </div>
                        </CustomersLogoStrip>
                    </LeftColumn>
                    <RightColumn>
                        <IllustrationContainer>
                            <img className="hero-img" src={HeroBgImage} alt="Logo"/>
                            <img className="bg-img" src={JeffGIF} alt="JEF Illustration"/>
                        </IllustrationContainer>
                    </RightColumn>
                </TwoColumn>
                <DecoratorBlob1/>
            </Container>
        </>
    );
};
